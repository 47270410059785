import Vue from 'vue'
import firebase from 'firebase/app'
import App from './App.vue'
import router from './router'

const firebaseConfig = {
  apiKey: "AIzaSyA8YtukwGzwuACnjFWhluRjovwYqtx9eSI",
  authDomain: "mueller-blog.firebaseapp.com",
  projectId: "mueller-blog",
  storageBucket: "mueller-blog.appspot.com",
  messagingSenderId: "654954154364",
  appId: "1:654954154364:web:324d8bc97e173fc57ffcf9"
};

firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
