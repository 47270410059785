<template>
  <div class="detail">
    <div class="detail-content">
      <blockquote>{{date}}</blockquote>
      <h1 class="detail-content">{{title}}</h1>
      <h3 v-if="description">{{description}}</h3>
    </div>
    <img v-if="cover" class="cover" v-bind:src="cover" />
    <div class="detail-content">
      <vue-markdown :source="src" />
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown-render'
import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  name: 'Detail',
  components: {
    VueMarkdown
  },
  data() {
    return {
      src: '',
      title: '',
      cover: '',
      description: '',
      date: ''
    }
  },
  async mounted() {
    const db = firebase.firestore()
    const page = await db.collection('pages').doc(this.$route.params.page_id).get()
    if (page.exists) {
      const data = page.data()
      this.src = data.markdown
      this.title = data.title || ''
      this.cover = data.cover
      this.description = data.description || ''
      
      const date = (new Date(data.publishedDate)).toDateString().split(' ');
      this.date = date[1] + ' ' + date[2];

      if (date[3] != (new Date()).getFullYear()) {
        this.date += ', ' + date[3];
      }
    }
  }
}
</script>

<style>
  .detail-content {
    width: 780px;
    margin: 24px auto;
  }

  .cover {
    width: 100%;
    border-radius: 0;
  }

  @media only screen and (max-width: 880px) {
    .detail-content {
      width: 80%;
    }
  }
</style>
