<template>
  <div class="home">
    <a class="page-link" v-for="doc in currentDocs" :key="doc.title" v-on:click="handleClick(doc.id)">
      <div class="page-preview">
        <blockquote>{{doc.date}}</blockquote>
        <h2>{{doc.title}}</h2>
        <h3 v-if="doc.description">{{doc.description}}</h3>
        <img class="preview-cover" v-if="doc.cover" v-bind:src="doc.cover" />
        <vue-markdown v-if="doc.preview" :source="doc.preview" />
      </div>
      <hr />
    </a>


    <div class="nav-wrapper" v-for="i in pages" :key="i">
      <a v-bind:class="{button: page !==i, 'button-active': page === i}" v-on:click="changePage(i)">
        {{i}}
      </a>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown-render'
import firebase from 'firebase/app'
import 'firebase/firestore'

const PAGE_SIZE = 5;

export default {
  name: 'Home',
  components: {VueMarkdown},
  data() {
    return {
      docs: [],
      currentDocs: [],
      page: 1,
      pages: []
    }
  },
  async mounted() {
    const db = firebase.firestore()
    const pages = await db.collection('pages').orderBy('publishedDate', "desc").get()

    const totalPages = Math.ceil(pages.size / PAGE_SIZE)
    for (let i = 1; i <= totalPages; i++) {
      this.pages.push(i)
    }

    pages.forEach((val) => {
      const doc = val.data()
      doc.id = val.id

      const date = (new Date(doc.publishedDate)).toDateString().split(' ');
      doc.date = date[1] + ' ' + date[2];
      if (date[3] != (new Date()).getFullYear()) {
        doc.date += ', ' + date[3];
      }

      this.docs.push(doc)
    })

    if (this.$route.query.page) {
      this.changePage(parseInt(this.$route.query.page))
    } else {
      this.changePage(1)
    }
  },
  methods: {
    handleClick(id) {
      this.$router.push({name: 'Detail', params: {page_id: id}})
    },
    changePage(i) {
      let start = (i - 1) * PAGE_SIZE
      if (start !== 0) start--
      this.currentDocs = this.docs.slice(start, (start + PAGE_SIZE - 1))
      this.page = i

      history.pushState({}, null, this.$route.path + (i === 1 ? '' : `?page=${i}`))
    }
  }
}
</script>

<style>
  .home {
    width: 780px;
    margin: auto;
  }

  .page-link {
    text-decoration: none;
    color: #3A4E65;
  }

  .page-link :hover {
    color: #3A4E65;
    cursor: pointer;
  }

  .button {
    display: inline-block;
    width: 59px;
    height: 57px;
    background: #F2F2F2;
    border: 2px solid rgba(58, 78, 101, 0.2);
    box-sizing: border-box;
    border-radius: 16px;
    text-decoration: none;
    color: #3A4E65;
    opacity: .6;
    text-align: center;
    padding-top: 13px;
    margin-right: 8px;
  }

  .button:hover {
    border: 2px solid rgba(58, 78, 101, 0.6);
    opacity: 1;
    color: #3A4E65;
    cursor: pointer;
  }

  .button-active {
    display: inline-block;
    width: 59px;
    height: 57px;
    background: #5F6F81;
    box-sizing: border-box;
    border-radius: 16px;
    text-decoration: none;
    color: #F2F2F2;
    text-align: center;
    padding-top: 14px;
    margin-right: 8px;
  }

  .nav-wrapper {
    display: inline-block;
    margin-top: 30px;
    vertical-align: middle;
  }

  .page-preview {
    margin-top: 38px;
  }

  @media only screen and (max-width: 880px) {
    .home {
      width: 80%;
    }
  }
</style>
